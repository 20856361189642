<template>
  <div class="setBox">
    <div class="boxTop">
      <div class="topLeft" @click="goBack()"></div>
      <div class="topCon">我的粉丝</div>
    </div>
    <div class="searBox">
      <img src="../../image/quanz/s.png" alt="" />
      <input type="text" placeholder="请输入成员昵称" />
    </div>
    <div class="fsNum">我的粉丝（{{tomeList.length}}）人</div>
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        :immediate-check="false"
        offset="800"
        @load="onLoad"
      >
    <div class="container" v-for="item in tomeList" :key="item.id">
      <div class="fansHear" @click="gotrends(item.userId)"><img :src="item.userHeadImg" alt=""></div>
      <div class="nameBrief">
        <div>{{item.notes}}</div>
        <div>{{item.schoolName  }}</div>
      </div>
      <div class="gzStyle">
      <img src="../../image/quanz/gz.png" alt="" v-if="item.status === 1" @click="gzEvent(item)"/>
       <img src="../../image/quanz/ygz.png" alt="" v-if="item.status === 2" @click="qxGzEvent(item)"/>
      </div>
      <div class="gdStyle">
        <img src="../../image/quanz/gd.png" alt="" @click="showPopup(item)" />
      </div>
    </div>
      </van-list>
    </van-pull-refresh>
    <van-popup v-model="show" round position="bottom">
      <div class="popupDiv"  @click="gochatPage()">私信</div>
      <div class="popupDiv" @click="lhEveent()">拉黑</div>
<!--      <div class="popupDiv1">举报/投诉</div>-->
      <div class="line"></div>
      <div class="popupDiv1" @click="show = false">取消</div>
    </van-popup>

    <van-overlay :show="showOver" @click="showOver = false">
      <div class="wrapper" @click.stop>
        <div class="block">
          <div class="blockTop">将“低调的先生”移除黑名单</div>
          <div class="blockContent">
            移除后对方将可以关注你、评论或点赞你的圈子、向你发私信等。
          </div>
          <div class="blockBtn">
            <div @click="showOver = false">取消</div>
            <div @click="removeEvent()">确定</div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { followTome ,followAdd , followCancel ,blackAdd} from "@/api/circle";
export default {
  data() {
    return {
      show: false,
      showOver: false,
      tomeList:[],
      black:{
        userId:''
      },
      pageNum: 1,
      pageSize: 10,
      total: 0,
      isLoading: false,
      loading: false, //上拉加载
      finished: false, //加载与不加载的状态
    };
  },
  mounted() {
    this.getfollowTome()
  },
  methods: {
    getfollowTome(){
      followTome({
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }).then(res=>{
        this.tomeList = this.tomeList.concat(res.data.data.items);
        if (this.tomeList.length >= res.data.data.total) {
          this.finished = true;
        }
      })
    },
    goBack() {
      this.$router.go(-1);
    },
    // 回复
    hfEvent() {},
    // 更多
    showPopup(item) {
      this.black.userId = item.userId
      this.show = true;
    },
    // 拉黑弹框
    lhEveent() {
      this.showOver = true;
      this.show = false;
    },
    // 确定拉黑
    removeEvent() {
      // this.show = false;
      // blackAdd(this.black).then(res=>{
      //   console.log(res,'拉黑');
      //   if(res.data.code === 0){
      //     this.showOver = false;
      //     this.tomeList = [];
      //     this.getfollowTome()
      //   }
      // })
      this.show = false;
      // 请注意：即使只添加一个用户账号到黑名单，也需要用数组类型，例如：userIDList: ['user1']
      let promise = this.tim.addToBlacklist({userIDList: [this.black.userId + ""]});
      promise.then(function(imResponse) {
        console.log(imResponse.data); // 成功添加到黑名单的账号信息，结构为包含用户 userID 的数组 - [userID]
      }).catch(function(imError) {
        console.warn('addToBlacklist error:', imError); // 添加用户到黑名单列表失败的相关信息
      });
      this.showOver = false;
      this.tomeList = [];
      this.getfollowTome()

    },
    // 私信
    gochatPage() {
      this.$router.push({ path: "/chatPage", query: {userId: this.black.userId} });
    },
    // 跳转动态
    gotrends(userId) {
      this.$router.push({ path: "/TrendIndex", query: {userId: userId} });
    },
    // 关注
    gzEvent(item){
      console.log(item);
      let obj = {
        followId:item.userId
      }
      followAdd(obj).then(res=>{
         this.getfollowTome()
      })
    },
    // 取消关注
    qxGzEvent(item){
      followCancel(item.userId).then(res=>{
         this.getfollowTome()
      })
    },
    onRefresh() {
      this.pageNum = 1;
      this.tomeList = [];
      this.getfollowTome();
    },
    onLoad() {
      this.pageNum ++;
      this.getfollowTome();
    }
  },
};
</script>

<style scoped>
.boxTop {
  display: flex;
  align-items: center;
  margin-top: 18px;
}
.topLeft {
  width: 48px;
  height: 48px;
  background-image: url("../../image/back.png");
  background-size: 100% 100%;
  margin-left: 32px;
}
.topCon {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
  margin-left: 236px;
}
.searBox {
  width: 686px;
  height: 72px;
  background: #f6f8fa;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  margin-left: 32px;
  margin-top: 34px;
  font-size: 24px;
  font-weight: 400;
  color: #97a4b4;
  display: flex;
  align-items: center;
}
.searBox img {
  width: 32px;
  height: 32px;
  margin-left: 24px;
  margin-right: 12px;
}
.searBox input {
  width: 500px;
  border: none;
  background: #f6f8fa;
}
.fsNum {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
  margin-left: 32px;
  margin-top: 38px;
  margin-bottom: 16px;
}
.container {
  width: 686px;
  height: 144px;
  border-bottom: 1px solid #f6f8fa;
  margin-left: 32px;
  display: flex;
  align-items: center;
  /* padding-top: 32px;
  box-sizing: border-box; */
}
.fansHear img{
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.nameBrief {
  width: 370px;
  margin-left: 20px;
}
.nameBrief div:nth-child(1) {
  font-size: 28px;
  font-weight: 600;
  color: #0b1526;
}

.nameBrief div:nth-child(2) {
  font-size: 24px;
  font-weight: 400;
  color: #97a4b4;
  margin-top: 6px;
}
.gzStyle img {
  width: 120px;
  height: 56px;
}
.gdStyle img {
  width: 40px;
  height: 40px;
  display: flex;
  margin-left: 48px;
}
.popupDiv,
.popupDiv1 {
  width: 750px;
  height: 104px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  font-size: 32px;
  font-weight: 400;
  color: #0b1526;
  text-align: center;
  line-height: 104px;
  border-bottom: 1px solid #f6f8fa;
}
.popupDiv1 {
  border: none;
}
.line {
  width: 750px;
  height: 20px;
  background: #f6f8fa;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.block {
  width: 510px;
  height: 346px;
  background: #ffffff;
  border-radius: 24px 24px 24px 24px;
  opacity: 1;
}
.blockTop {
  width: 510px;
  height: 88px;
  border-bottom: 1px solid #f6f8fa;
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
  text-align: center;
  line-height: 88px;
}
.blockContent {
  font-size: 28px;
  width: 446px;
  font-weight: 400;
  color: #0b1526;
  margin-left: 32px;
  margin-top: 20px;
}
.blockBtn {
  display: flex;
  margin-top: 32px;
}
.blockBtn div:nth-child(1) {
  width: 160px;
  height: 84px;
  background: #f6f8fa;
  border-radius: 12px 12px 12px 12px;
  opacity: 1;
  font-size: 32px;
  font-weight: 400;
  color: #0b1526;
  text-align: center;
  line-height: 84px;
  margin-left: 32px;
}
.blockBtn div:nth-child(2) {
  width: 262px;
  height: 84px;
  background: #0957c3;
  border-radius: 12px 12px 12px 12px;
  opacity: 1;
  font-size: 32px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 84px;
  margin-left: 24px;
}
</style>
